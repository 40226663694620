<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">{{ $t('heading.hosting.domains.title') }}</h4>
        </v-col>

        <v-col
          v-show="items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="showAddDomainModal"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : $t('button.domain.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-domains-table
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="itemsLoading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @action-button-ssl-edit="loadEditSslModal"
              @action-button-edit="showEditModal"
              @action-button-delete="showDeleteModal"
              :itemClass="itemClass"
              :serverAccount="serverAccount"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('notification.emptyPage.domain.title')"
                  :desc="$t('notification.emptyPage.domain.description')"
                >
                  <template v-slot:image>
                    <hosting-domains-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      rounded
                      elevation="0"
                      color="primary"
                      class="p-2 add-new-button"
                      @click="showAddDomainModal"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus
                      </v-icon>
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.domain.add') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:type="item">
                <span>{{ item.type_text }}</span>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-domains-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      >
        <template v-slot:custom="{item}">
          <div class="mb-4" v-if="item.domain">
            <div
              v-if="item.domain.sslInstalledCert"
              class="d-inline-flex justify-center"
            >
              {{ $t('heading.instance.changeSslModal.serverCertificate') }}:
              <ssl-indicator
                :fetching="false"
                :ssl="
                  item.domain.sslInstalledCert
                    ? {
                        certificate_installed: true,
                        certificate: item.domain.sslInstalledCert,
                      }
                    : {
                        certificate_installed: false,
                        certificate: {},
                      }
                "
                :text="
                  item.domain.sslInstalledCert
                    ? item.domain.sslInstalledCert.self_signed
                      ? $t('general.sslProvider.selfSigned')
                      : item.domain.sslInstalledCert.issuer_name
                    : $t('general.sslProvider.none')
                "
                :textClass="
                  item.domain.sslInstalledCert
                    ? !(
                        item.domain.sslInstalledCert.self_signed ||
                        item.domain.sslInstalledCert.name_match
                      )
                      ? 'success--text'
                      : 'error--text'
                    : 'error-text'
                "
                :isIcon="false"
                class="ms-4 me-1 d-inline-block"
              />
            </div>
            <div
              v-if="item.domain.sslDetectedCert"
              class="d-inline-flex justify-center mb-1"
            >
              {{ $t('heading.instance.changeSslModal.detectedCertificate') }}:
              <ssl-indicator
                :fetching="false"
                :ssl="
                  item.domain.sslDetectedCert
                    ? {
                        certificate_installed: true,
                        certificate: item.domain.sslDetectedCert,
                      }
                    : {
                        certificate_installed: false,
                        certificate: {},
                      }
                "
                      :text="
                  item.domain.sslDetectedCert
                    ? item.domain.sslDetectedCert.self_signed
                      ? $t('general.sslProvider.selfSigned')
                      : item.domain.sslDetectedCert.issuer_name
                    : $t('general.sslProvider.none')
                "
                      :textClass="
                  item.domain.sslDetectedCert
                    ? !(
                        item.domain.sslDetectedCert.self_signed ||
                        item.domain.sslDetectedCert.name_match
                      )
                      ? 'success--text'
                      : 'error--text'
                    : 'error-text'
                "
                :isIcon="false"
                class="mx-4 d-inline-block"
              />
            </div>
          </div>
        </template>
      </basic-modal>
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingDomainsTable from "../../components/dataIterator/tables/HostingDomainsTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingDomainsIllustration from "../../components/illustrations/hosting/hosting-domains-illustration.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import EditSslMixin from "../../mixins/EditSslMixin";

import moment from "moment";
import SslIndicator from "@/components/security/SslIndicator.vue";

export default {
  components: {
    SslIndicator,
    BasicModal,
    DataIteratorFilterControls,
    HostingDomainsTable,
    TableFullScreenMessage,
    HostingDomainsIllustration,
  },
  mixins: [
    ActionModalMixin,
    DataIteratorPageMixin,
    CustomTablePageMixin,
    EditSslMixin,
  ],
  data() {
    return {
      isServerProvided: false,
      items: [],
      headers: [
        {
          text: this.$t('table.header.domain'),
          value: "domain",
          sortable: true,
        },
        {
          text: this.$t('table.header.ssl'),
          value: "ssl_status",
          sortable: true,
        },
        {
          text: this.$t('table.header.type'),
          value: "type",
          sortable: true,
        },
        {
          text: this.$t('table.header.documentRoot'),
          value: "document_root",
          sortable: true,
        },
        {
          text: this.$t('table.header.actions'),
          value: "actions",
        },
      ],
      sortBy: "domain",
      sortDesc: true,
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
      sslOffers: [],
    };
  },

  props: {
    serverAccount: Object,
  },
  methods: {
    showAddDomainModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.domains.modal.create.title');
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.domain.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addDomain(modal.formValues);
        },
      });

      let parentDomains = [];
      for (const domain of this.items) {
        if (domain.type == "sub" || domain.type == "alias") {
          continue;
        }
        parentDomains.push({
          label: domain.domain,
          value: domain.domain,
        });
      }

      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.domain'),
          name: "domain",
          type: "text",
          tooltip: "tooltip.add_domain.domain",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.domain')}),
            (v) =>
              (v && v.length <= 255) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.domain'),
                length: 255
              }),
            (v) => (v && /^[a-zA-Z0-9-_.]+$/.test(v)) ||
              this.$t('form.validation.valid', {field: this.$t('form.label.domain')}),
          ],
          placeholder: this.$t('form.placeholder.domain'),
          hidden: false,
        },
        {
          label: this.$t('form.label.domain'),
          type: "text-select",
          suffix: ".",
          textName: "domain",
          selectName: "parent_domain",
          tooltip: "tooltip.add_domain.domain",
          options: parentDomains,
          hidden: true,
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.domain')}),
            (v) => (v && /^[a-zA-Z0-9-_.]+$/.test(v)) ||
              this.$t('form.validation.valid', {field: this.$t('form.label.domain')}),
            (v) =>
              (v && v.length <= 255) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.domain'),
                length: 255
              }),
          ],
        },
        {
          label: this.$t('form.label.type'),
          name: "type",
          tooltip: "tooltip.add_domain.type",
          type: "select",
          options: [
            {
              label: this.$t('form.option.addon'),
              value: "addon",
            },
            {
              label: this.$t('form.option.subdomain'),
              value: "sub",
            },
          ],
          required: true,
          onChange: (values) => {
            if (values.type == "sub") {
              for (const field of this.modalOptions.formFields) {
                if (field.name == "domain") {
                  field.hidden = true;
                }
                if (field.selectName == "parent_domain") {
                  field.hidden = false;
                }
              }
            } else {
              for (const field of this.modalOptions.formFields) {
                if (field.name == "domain") {
                  field.hidden = false;
                }
                if (field.selectName == "parent_domain") {
                  field.hidden = true;
                }
              }
            }
          },
        },
      ];
      this.modalOptions.item = {
        type: "addon",
        parent_domain: parentDomains[0].value,
      };
      this.modalOptions.open = true;
    },
    addDomain(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      let domainType = this.$t('form.label.domain');

      let postData = { ...formData };
      if (postData.type == "sub") {
        postData.domain += "." + postData.parent_domain;
        domainType = this.$t('form.label.subdomain');
      }

      Api.post(`/server-accounts/${this.serverAccount.id}/domains`, postData)

        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.domain.create.success', {type: domainType});
          this.highlightItem = postData;
          Api.hosting(this.serverAccount.id).resetCache();
          this.reloadData(true);

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showEditModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.domains.modal.update.title', {domain: `<span style="text-wrap: nowrap">${item.domain}</span>`});
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateDomain(item, modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.documentRoot'),
          name: "document_root",
          tooltip: "tooltip.edit_domain.document_root",
          type: "text",
          rules: [
            (v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.documentRoot')}),
            (v) =>
              (v && v.length <= 255) ||
              this.$t('form.validation.maxLength', {
                field: this.$t('form.label.documentRoot'),
                length: 255
              }),
          ],
          placeholder: this.$t('form.placeholder.documentRoot'),
          hidden: item.type == "main",
        },
        {
          label: this.$t('form.label.redirectDomain'),
          name: "redirect_enabled",
          type: "checkbox",
          onChange: (values) => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "redirect_url") {
                field.hidden = !values.redirect_enabled;
                break;
              }
            }
          },
        },
        {
          label: this.$t('form.label.redirectUrl'),
          name: "redirect_url",
          tooltip: "tooltip.edit_domain.redirect_url",
          type: "text",
          hidden: !item.redirect_enabled,
        },
      ];
      this.modalOptions.open = true;
    },
    updateDomain(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(
        `/server-accounts/${this.serverAccount.id}/domains/${item.domain}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.domain.update.success', {type: item.type_text});
          this.highlightItem = item;
          this.reloadData(true);

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.domains.modal.delete.title')
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.domain'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteDomain(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteDomain(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/domains/${item.domain}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.domain.delete.success', {type: item.type_text});
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    reloadData(sortByNewest = false) {
      this.itemsLoading = true;
      let sortQuery = sortByNewest ? "?sort_by=updated_at" : "";
      Api.cached(0)
        .get(`/server-accounts/${this.serverAccount.id}/domains${sortQuery}`)
        .then((response) => {
          this.items = response.data.data.map((item) => {
            item.nsStatus = "ok";
            if (item.type != "sub" && item.has_dns_zone) {
              Api.dns()
                // .nsStatus(item.domain, this.serverAccount.nameservers)
                .aStatus(item.domain, this.serverAccount.ip_address)
                .then((status) => {
                  item.nsStatus = status;
                });
            }
            //
            this.loadDomainDetectedSslStatus(item);
            this.loadDomainInstalledSslStatus(item);
            //
            item.editSslButtonLoading = false;
            item.serverAccountId = this.serverAccount.id;
            //
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            item.type_text = item.type;
            switch (item.type) {
              case "main":
                item.type_text = this.$t('heading.hosting.domains.domain.main');
                break;
              case "addon":
                item.type_text = this.$t('heading.hosting.domains.domain.addon');
                break;
              case "sub":
                item.type_text = this.$t('heading.hosting.domains.domain.sub');
                break;
            }
            item.manage_ssl = true;
            if (!response.data.meta.actions.manage_subdmomains_ssl) {
              item.manage_ssl = item.type === "addon" || item.type === "main";
            }
            return item;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
    loadDomainDetectedSslStatus(item) {
      item.sslStatusLoading = true;
      item.sslDetectedCert = null;
      Api.hosting(this.serverAccount.id)
        .getDetectedCertificate(item.domain)
        .then((cert) => {
          item.sslStatusLoading = false;
          item.sslDetectedCert = cert;
        });
    },
    loadDomainInstalledSslStatus(item) {
      item.sslStatusLoading = true;
      item.sslInstalledCert = null;
      Api.hosting(this.serverAccount.id)
        .getInstalledCertificate(item.domain)
        .then((cert) => {
          item.sslStatusLoading = false;
          item.sslInstalledCert = cert;
        });
    },
    itemClass(item) {
      if (
        this.highlightItem.domain &&
        item.domain == this.highlightItem.domain
      ) {
        return "highlight";
      }
    },
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    serverAccount: function () {
      this.reloadData();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  computed: {
    itemsTotal: function () {
      return this.items.length;
    },
  },
  mounted: function () {
    if (this.serverAccount) {
      this.reloadData();
    }
    this.$on("ssl-certificate-installed", (item) => {
      Api.hosting(this.serverAccount.id).resetCache();
      this.loadDomainSslStatus(item);
    });
    this.$on("ssl-certificate-ordered", (item) => {
      for (let thisitem of this.items) {
        if (thisitem.domain == item.domain) {
          thisitem.ssl_order_status = "pending";
          return;
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
